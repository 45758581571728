import React, {useState, useEffect, useRef} from 'react'
import { useErrorBoundary } from 'use-error-boundary'
import { useCookies } from 'react-cookie'
import LoadingBox from './design/loadingbox'
import Logo from './design/logo'
import { navigate } from 'gatsby'
import { decodeCookie, loginFlow } from '../utils/constants.js'
import { ValidateContext } from '../contexts/validate'


// This component wraps UI that we only want logged in users to see -- it will handle the login flow
let heartbeat = null // Prep the interval

// Check for heartbeat and clear if it exists
let clearHeartbeat = () => {
  if (heartbeat !== null){
    clearInterval(heartbeat)
    heartbeat = null
  }
}

// Actually handle the success case
let handleSuccess = (lambdaUri, setCookie, token, resData, airData, validWrapper, validateData, setStatus, forceViewer) => {
  // Ok, let's get their info saved
  let cookieOpts = {path: '/', sameSite: 'strict', maxAge: 2592000} // 30 day max age
  // Don't make a secure cookie for dev
  // NOTE secure: 'secure' will prevent cookies from being used on localhost for Safari
  if (lambdaUri.indexOf("localhost") !== -1){
    cookieOpts.secure = 'secure'
  }
  setCookie('paverReg', window.btoa(JSON.stringify({
    // Personal token: WE ARE USING THIS PRIMARILY NOW
    token: token,
    // ArcGIS data
    username: resData.user.username,
    fullname: resData.user.fullName,
    firstname: resData.user.firstName,
    email: resData.user.email,
    // Airtable data
    pavauth: airData.details.PavAuth,
    role: forceViewer ? "Viewer" : airData.details.Role,
    org: airData.details.OrgName,
    id: airData.details.MemberId,
    layers: airData.details.Layers,
    dataaccess: airData.details['Data Access'] || "",
    admin: airData.details.Admin
  })), cookieOpts)
  const viewLayers = process.env.GATSBY_ENV !== "prod" ? airData.details.StageViewLayers : airData.details.ViewLayers
  if (!viewLayers){
    setStatus("unconfigured")
    return false
  }
  // Set everything else that doesn't need to be in the cookie in an object
  // Actually ... maybe most things don't need to be in a cookie? We'll think about it
  validateData.current = {
    // If we're on local or on stage, use the stage views
    viewLayers: JSON.parse(viewLayers)
  }
  // Send the user into the gated app
  setStatus("registered")
  // Start heartbeat to regularly check if user has a valid token (and the DOM exists -- it might not if we were mid-transition when this request started)
  if (heartbeat === null && validWrapper.current){
    heartbeat = setInterval(() => {
      checkUser(lambdaUri, token, setStatus, setCookie, validWrapper)
    }, 60000) // Once every minute
  }
}

// Check to see if the user's token has the required permissions, and update our cookie if so
let checkUser = (lambdaUri, token, setStatus, setCookie, validWrapper, validateData) => {
  fetch('https://www.arcgis.com/sharing/rest/portals/self?f=json&token='+token)
  .then(response => response.json())
  .then(resData => {
    // If we've got a user but it isn't valid (or if this token is not valid at all)
    if (!resData.user || !resData.user.username){
      setStatus("invalid")
    } else {
      // If we're just checking for token validation, we can bail out here
      if (heartbeat !== null){
        return true
      }       

      // Check the user's role -- if it's "viewerUT", force them to be a viewer (despite what Airtable says)
      let forceViewer = false
      if (resData.user.userLicenseTypeId === "viewerUT"){
        forceViewer = true
      }
      // Ok we have a user, but are they configured to use our app?
      // Make one more request to check if they are registered in Airtable
      let requestString = lambdaUri+'/.netlify/functions/verify-user?username='+resData.user.username+'&token='+token
      fetch(requestString)
      .then(response => response.json())
      .then(airData => {
        //console.log("VALIDATE RESP", airData)
        if (airData.status !== "ok"){
          if (airData.error === "Error: Unauthorized user"){
            // This user is not registered to use our app! Tell them to go register
            setStatus("unregistered")
          } else {
            // Ok, we don't know why this failed, so serve a generic error
            setStatus("error")
          }
        } else {
          // We have registration confirmation! Do one last check to see if they are suspended
          //console.log("airData.details", airData.details)
          let status = airData.details['Status (from Organization)']
          if (
            (status.length > 0 && status[0] === "Suspended") ||
            (airData.details['User Status'] && airData.details['User Status'] !== "Active")
          ){
            setStatus("suspended")
          } else {
            // Let's proceed
            handleSuccess(lambdaUri, setCookie, token, resData, airData, validWrapper, validateData, setStatus, forceViewer)
          }
        }
      }).catch((error) => {
        // Something went wrong, just tell them to go register
        console.log("ERR", error)
        setStatus("unregistered")
      })
    }
  })
}

const Validate = ({ children, redirectUri, lambdaUri }) => {
  let [status, setStatus] = useState("pending")
  const [cookies, setCookie, removeCookie] = useCookies(['paverReg'])
  const { ErrorBoundary, didCatch, error } = useErrorBoundary()
  let validWrapper = useRef(null)
  let validateData = useRef(null)

  useEffect(() => {
    if (status === "pending"){
      // Prep the global function to catch the oauth callback
      if (typeof window !== "undefined"){
        window.oauthCallback = (token) => {
          // Now make ANOTHER call to get user data for the cookie
          checkUser(lambdaUri, token, setStatus, setCookie, validWrapper, validateData)
        }
      }

      // Check if the user has a token and if that token is still good
      if (cookies.paverReg){
        let userObj = {}
        try {
          // Somehow, this doesn't need to be JSON.parsed, it just loads up as JSON -- whatever, that's cool
          userObj = decodeCookie(cookies.paverReg)
        } catch (err){
          // It's ok, it'll error gracefully below
          //console.log("ERR", err)
        }
        checkUser(lambdaUri, userObj['token'], setStatus, setCookie, validWrapper, validateData)
      } else {
        // If there's no cookie at all, set invalid
        setStatus("invalid")
      }

      // Create a catch-all for unhandled rejections
      const globalPromiseRejectionHandler = (event) => {
        // This is likely because the token timed out, so just reboot this component
        console.log('Unhandled promise rejection reason: ', event.reason)
        if (event.reason.message && event.reason.message.indexOf("inaccessible") !== -1){
          // If ArcGIS fails to serve on of our views, don't force users into a reload loop
        } else {
          // Otherwise, maybe this is something that can be fixed on reload
          setStatus("pending")
        }
      }
      window.onunhandledrejection = globalPromiseRejectionHandler
    }
    
    // Clean up the interval
    return () => {
      clearHeartbeat()
    }
  }, [status])

  // Clean up heartbeat if we changed states away from registered
  useEffect(() => {
    if (status !== "registered"){
      clearHeartbeat()
    }
  }, [status])

  if (status !== "registered"){
    return (
      <div className="valid-check floating">
        {status === "pending" &&
          <LoadingBox text="Loading" />
        }
        {status === "invalid" &&
          <div className="button-options">
            <Logo />
            <button className="primary" onClick={() => {loginFlow(redirectUri)}}>Sign in</button>
            <button className="secondary" onClick={() => {navigate("/")}}>Return Home</button>
          </div>
        }
        {status === "unregistered" &&
          <div className="button-options">
            <Logo />
            <p className="helptext">Your ArcGIS login is valid but either your organization is not yet registered for PaverOps or your login is not part of your organization's subscription. Request access from your PaverOps admin or contact us.</p>
            <button className="primary" onClick={() => {loginFlow(redirectUri)}}>Switch accounts</button>
            <button className="secondary" onClick={() => {navigate("/contact")}}>Contact</button>
          </div>
        }
        {status === "error" &&
          <div className="button-options">
            <Logo />
            <p className="helptext">Something unexpected happened. Please reload and try again.</p>
            <button className="primary" onClick={() => {
              if (window){
                window.location.reload(true)
              }
            }}>Reload page</button>
            <button className="secondary" onClick={() => {navigate("/#register")}}>Register</button>
          </div>
        }
        {status === "suspended" &&
          <div className="button-options">
            <Logo />
            <p className="helptext">Your user account or your organization is temporarily unable to access PaverOps due to a permissions issue. Contact your administrator or email <a href="mailto:support@paverops.com">PaverOps support</a> to enable your account.</p>
            <button className="primary" onClick={() => {loginFlow(redirectUri)}}>Sign in</button>
            <button className="secondary" onClick={() => {navigate("/#register")}}>Register</button>
          </div>
        }
        {status === "unconfigured" &&
          <div className="button-options">
            <Logo />
            <p className="helptext">This account is valid but hasn't been configured by the PaverOps support team yet. If this is unexpected, please contact them using the button below.</p>
            <button className="primary" onClick={() => {loginFlow(redirectUri)}}>Switch accounts</button>
            <button className="secondary" onClick={() => {navigate("/contact/")}}>Contact</button>
          </div>
        }
      </div>
    )
  }

  // If the user is valid, show the children as if this component did not exist
  if (status === "registered"){
    return (
      <div ref={validWrapper} id="validation-layer">
        {didCatch ? (
          <p>An error has been caught: {error.message}</p>
        ) : (
          <ErrorBoundary>
            <ValidateContext.Provider value={validateData.current}>
              {children}
            </ValidateContext.Provider>
          </ErrorBoundary>
        )}
      </div>
    )
  }
}

export default Validate